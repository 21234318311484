<template>
    <section class="about">
        <div class="container">
            <div class="row">
                <div class="col" v-html="pageData.content"></div>
            </div>
        </div>
    </section>
</template>

<script>
import Static from "./Static";

export default {
    name: "About",
    extends: Static
};
</script>

<style>
.about {
    color: #fff;
}
</style>
